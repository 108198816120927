import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import TextureGuideLanding from 'components/TextureGuideLanding';
import SEO from 'components/seo';
import get from 'lodash.get';

export default function TextureGuideLandingPage(props) {
    let {
        data: { contentfulTextureLandingPage },
    } = props;

    return (
        <Layout>
            <SEO
                description={get(
                    contentfulTextureLandingPage,
                    'seoListing.description.description'
                )}
                title={get(contentfulTextureLandingPage, 'seoListing.seoTitle')}
                slug={'texture-guide'}
            />
            <TextureGuideLanding page={contentfulTextureLandingPage} />
        </Layout>
    );
}

export const query = graphql`
    query($locale: String) {
        contentfulTextureLandingPage(node_locale: { eq: $locale }) {
            ...ContentfulTextureLandingPageFields
        }
    }
`;
