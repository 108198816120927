import React, { useState, useEffect } from 'react';
import get from 'lodash.get';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { injectIntl } from 'gatsby-plugin-react-intl';
import { isMobile } from 'react-device-detect';
import { getProductUrl } from 'helpers/url';

import {
    Spacing,
    SharedStyles,
    Container,
    ImageTextGridBanner,
    Filter,
    TabContainer,
    TabHeader,
    TabOptionData,
    TabOptionDataStyling,
    TextureProductList,
    FilterNav,
} from '../kenra-storybook/index';

const { StSectionInfo, StTitle, StSubTitle } = SharedStyles;

const Filters = ['Curl Type', 'Texture', 'Porosity', 'Styling Technique '];

const TextureGuideLanding = ({ intl, page }) => {
    const [currentFilter, setCurrentFilter] = useState(Filters[0]);
    const [currentTab, setCurrentTab] = useState(0);
    const [categoryFilter, setCategoryFilter] = useState([[], [], [], []]);

    let tabs = page.tabs;
    let showAll = true;
    categoryFilter.forEach(topLevel => {
        if (topLevel.length > 0) {
            showAll = false;
        }
    });

    function sortCategoryFilter(filter) {
        let updatedCategoryFilter = [...categoryFilter];
        let itemIndex = updatedCategoryFilter[filter[0].index].indexOf(
            filter[0].itemIndex
        );
        if (itemIndex > -1) {
            updatedCategoryFilter[filter[0].index].splice(itemIndex, 1);
        } else {
            updatedCategoryFilter[filter[0].index].push(filter[0].itemIndex);
        }
        setCategoryFilter(updatedCategoryFilter);
    }

    function clearCategoryFilter() {
        setCategoryFilter([[], [], [], []]);
    }

    // Get all products
    let productItems = [];
    let categories = [];
    page.textureFilterTypes.forEach(filterType => {
        if (filterType.type === 'Category') {
            filterType.textureFilters.forEach(filter => {
                let category = {
                    color: filter.color,
                    name: filter.name,
                    description: filter.description,
                    products: [],
                };

                filter.products.forEach(product => {
                    category.products.push({
                        handle: product.shopifyProduct.handle,
                    });
                });
                categories.push(category);
            });
        }
    });

    let filterMenu = [];
    let filterTopLevel = {
        href: null,
        title: intl.formatMessage({ id: 'filter-text' }),
        image: get(page, 'filterImage.localFile.publicURL'),
        items: [],
    };

    page.textureFilterTypes.forEach((filterType, index) => {
        if (filterType.type === 'Filter') {
            let filterSecondLevel = {
                itemsTitle: filterType.title,
                itemIndex: index,
                items: [],
            };
            filterType.textureFilters.forEach((filter, index) => {
                filterSecondLevel.items.push({
                    title: filter.name,
                    itemIndex: index,
                });

                // does the filter show this range of products?
                if (
                    showAll ||
                    (categoryFilter[filterSecondLevel.itemIndex].length > 0 &&
                        categoryFilter[filterSecondLevel.itemIndex].includes(
                            index
                        ))
                ) {
                    filter.products.forEach(product => {
                        let category = [];
                        categories.forEach((categoryItem, index) => {
                            let categoryData = categoryItem.products.find(
                                categoryProduct =>
                                    categoryProduct.handle ===
                                    product.shopifyProduct.handle
                            );

                            if (typeof categoryData !== 'undefined') {
                                category.push({
                                    text: categoryItem.name,
                                    color: categoryItem.color,
                                    description: categoryItem.description,
                                });
                            }
                        });
                        productItems.push({
                            link: getProductUrl(product.shopifyProduct.handle),
                            img: get(
                                product,
                                'productItem.images.0.localFile.publicURL'
                            ),
                            title: get(product, 'productItem.title'),
                            category: category,
                        });
                    });
                }
            });
            filterTopLevel.items.push(filterSecondLevel);
        }
    });
    filterMenu.push(filterTopLevel);

    // Remove any duplicate products
    productItems = productItems.filter(
        (product, index, self) =>
            index === self.findIndex(elem => elem['link'] === product['link'])
    );
    let blankObj = '{}'
    //         let isMobile = true;
    return (
        <>
            <Spacing removeSpaceTop removeSpaceBottom>
                <ImageTextGridBanner
                    styleSquare
                    rowRevers
                    item={{
                        img: get(page, 'bannerImage.localFile.publicURL'),
                        imgMobile: get(
                            page,
                            'bannerMobileImage.localFile.publicURL'
                        ),
                        text: documentToReactComponents(
                            JSON.parse(get(page, 'bannerText.raw') || blankObj)
                        ),
                    }}
                ></ImageTextGridBanner>
            </Spacing>
            <Spacing removeSpaceTop>
                <StSectionInfo
                    style={{
                        backgroundColor: '#f6ece9',
                    }}
                >
                    <Filter
                        items={tabs.map((tab, index) => {
                            return {
                                text: tab.title,
                                onClick: () => {
                                    setCurrentFilter(Filters[index]);
                                    setCurrentTab(0);
                                },
                                isActive: currentFilter === Filters[index],
                            };
                        })}
                        ignoreMobileActive
                    />
                    <Spacing removeSpaceTop>
                        <TabContainer
                            style={{
                                backgroundColor: '#fff',
                            }}
                        >
                            <Spacing>
                                {tabs &&
                                    tabs.map((tab, index) => {
                                        if (currentFilter === Filters[index]) {
                                            let optionData =
                                                tab.optionData[currentTab];
                                            if (!optionData) {
                                                return <></>;
                                            }
                                            let benefitRaw = get(
                                                optionData,
                                                'benefits.raw')
                                            let benefitObj = JSON.parse(blankObj)
                                            if(benefitRaw) {
                                                benefitObj = documentToReactComponents(JSON.parse(benefitRaw))
                                            } else {
                                                benefitObj = []
                                            }
                                            let data = {
                                                title: optionData.title,
                                                text: documentToReactComponents(
                                                    JSON.parse(get(optionData, 'text.raw'))
                                                ),
                                                benefits: benefitObj,
                                                imageData:
                                                    optionData.newImages &&
                                                    optionData.newImages.fragments.map(
                                                        image => {
                                                            let returnData = {};
                                                            image.forEach(
                                                                fragmentData => {
                                                                    switch (
                                                                        fragmentData.key
                                                                    ) {
                                                                        case 'Image':
                                                                            returnData[
                                                                                'img'
                                                                            ] =
                                                                                fragmentData.value;
                                                                            break;
                                                                        case 'Text':
                                                                            returnData[
                                                                                'text'
                                                                            ] =
                                                                                fragmentData.value;
                                                                            break;
                                                                        default:
                                                                            break;
                                                                    }
                                                                }
                                                            );
                                                            return {
                                                                image:
                                                                    returnData.img,
                                                                text: returnData.text
                                                                    ? returnData.text
                                                                    : null,
                                                            };
                                                        }
                                                    ),
                                                imageGradient:
                                                    optionData.imageGradient,
                                            };
                                            return (
                                                <>
                                                    <TabHeader
                                                        key={index}
                                                        text={documentToReactComponents(
                                                            JSON.parse(get(
                                                                tab,
                                                                'text.raw'
                                                            ) || blankObj)
                                                        )}
                                                        buttons={
                                                            tab.options &&
                                                            tab.options.map(
                                                                (
                                                                    option,
                                                                    index
                                                                ) => {
                                                                    return {
                                                                        text: option,
                                                                        gradient:
                                                                            '254deg, #c86dd7 0%, #3023ae 100%',
                                                                        isActive:
                                                                            currentTab ===
                                                                            index,
                                                                        onClick: () =>
                                                                            setCurrentTab(
                                                                                index
                                                                            ),
                                                                    };
                                                                }
                                                            )
                                                        }
                                                    />
                                                    <Spacing key={index + 'sp'}>
                                                        {currentFilter ===
                                                            Filters[0] && (
                                                            <TabOptionData
                                                                bkgndColor="#fafafa"
                                                                benefitsTitle={intl.formatMessage(
                                                                    {
                                                                        id:
                                                                            'texture-benefits-title',
                                                                    }
                                                                )}
                                                                item={data}
                                                                isMobile={
                                                                    isMobile
                                                                }
                                                            />
                                                        )}
                                                        {currentFilter ===
                                                            Filters[1] && (
                                                            <TabOptionData
                                                                bkgndColor="#fafafa"
                                                                benefitsTitle={intl.formatMessage(
                                                                    {
                                                                        id:
                                                                            'texture-benefits-title',
                                                                    }
                                                                )}
                                                                item={data}
                                                                isMobile={
                                                                    isMobile
                                                                }
                                                                highlightImage
                                                                activeImage={
                                                                    currentTab
                                                                }
                                                            />
                                                        )}
                                                        {currentFilter ===
                                                            Filters[2] && (
                                                            <TabOptionData
                                                                bkgndColor="#fafafa"
                                                                benefitsTitle={intl.formatMessage(
                                                                    {
                                                                        id:
                                                                            'texture-benefits-title',
                                                                    }
                                                                )}
                                                                item={data}
                                                                isMobile={
                                                                    isMobile
                                                                }
                                                                highlightImage
                                                                activeImage={
                                                                    currentTab
                                                                }
                                                            />
                                                        )}
                                                        {currentFilter ===
                                                            Filters[3] && (
                                                            <TabOptionDataStyling
                                                                bkgndColor="#fafafa"
                                                                item={data}
                                                                isMobile={
                                                                    isMobile
                                                                }
                                                            />
                                                        )}
                                                    </Spacing>
                                                </>
                                            );
                                        }
                                    })}
                            </Spacing>
                        </TabContainer>
                    </Spacing>
                </StSectionInfo>
                <Spacing>
                    <StTitle>
                        <h2>{page.filterTitle}</h2>
                    </StTitle>
                    <StSubTitle>
                        <p>{page.holdScaleText}</p>
                        <img
                            src={get(
                                page.holdScaleImage,
                                'localFile.publicURL'
                            )}
                        ></img>
                    </StSubTitle>
                    <FilterNav
                        items={filterMenu}
                        setFilter={sortCategoryFilter}
                        clearFilter={clearCategoryFilter}
                        categoryFilter={categoryFilter}
                        isMobile={isMobile}
                        mobileText={intl.formatMessage({ id: 'filter-text' })}
                        clearText={intl.formatMessage({
                            id: 'filter-clear-text',
                        })}
                        applyText={intl.formatMessage({
                            id: 'filter-apply-text',
                        })}
                    />
                    <Container>
                        <TextureProductList items={productItems} />
                    </Container>
                </Spacing>
            </Spacing>
        </>
    );
};

export default injectIntl(TextureGuideLanding);

/*
                                            let data = {
                                                title: optionData.title,
                                                text: documentToReactComponents(
                                                    get(optionData, 'text.json')
                                                ),
                                                benefits: documentToReactComponents(
                                                    get(
                                                        optionData,
                                                        'benefits.json'
                                                    )
                                                ),
                                                imageData:
                                                    optionData.images &&
                                                    optionData.images.map(
                                                        image => {
                                                            return {
                                                                image: get(
                                                                    image,
                                                                    'image.localFile.publicURL'
                                                                ),
                                                                text:
                                                                    image.text,
                                                            };
                                                        }
                                                    ),
                                                imageGradient:
                                                    optionData.imageGradient,
                                            };

*/
